var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{attrs:{"id":"page-pricing"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('category-list')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"justify-center event-title"},[_c('h2',{staticClass:"text-center"},[_vm._v("Tarifas")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Ubicaciones Especiales Físico","items":_vm.options
                        .filter(
                          function (o) { return o.type === 'Físico' && o.location === 'Especial'; }
                        )
                        .map(function (o) { return ({
                          text: o.name,
                          value: o.id,
                          type: o.type,
                          location: o.location,
                        }); }),"clearable":"","placeholder":"Seleccione una opción","hint":"Las ubicaciones especiales se limitan a una (1) por edición","persistent-hint":"","dense":"","outlined":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Ubicaciones Normales Físico","items":_vm.options
                        .filter(
                          function (o) { return o.type === 'Físico' && o.location !== 'Especial'; }
                        )
                        .map(function (o) { return ({
                          text: o.name,
                          value: o.id,
                          type: o.type,
                          location: o.location,
                        }); }),"clearable":"","placeholder":"Seleccione una opción","dense":"","outlined":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"label":"Ubicaciones Virtuales","items":_vm.options
                        .filter(function (o) { return o.type === 'Virtual'; })
                        .map(function (o) { return ({
                          text: o.name,
                          value: o.id,
                          type: o.type,
                          location: o.location,
                        }); }),"clearable":"","placeholder":"Seleccione una opción","dense":"","outlined":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[(_vm.selected)?_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"justify-center"},[_c('h2',{domProps:{"textContent":_vm._s(
                  ("Precio: " + (_vm.selectedOption.price.toLocaleString('es', {
                    style: 'currency',
                    currency: 'COP',
                    currencyDisplay: 'symbol',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })) + " + IVA")
                )}})])],1):_vm._e()],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.selected &&
              (_vm.selectedOption.blocks.blocks.length > 0 ||
                _vm.selectedOption.image_path)
            ),expression:"\n              selected &&\n              (selectedOption.blocks.blocks.length > 0 ||\n                selectedOption.image_path)\n            "}],staticClass:"elevation-0"},[(_vm.selected)?_c('v-card-title',{domProps:{"textContent":_vm._s(_vm.selectedOption.name)}}):_vm._e(),_c('v-card-text',[_c('block-renderer',{ref:"br"}),(_vm.selected && _vm.selectedOption.image_path)?_c('v-img',{attrs:{"src":_vm.getUrl(_vm.selectedOption.image_path),"max-height":400,"contain":""}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }