































































































































































































import Vue from "vue";
import { mdiStar } from "@mdi/js";

import CategoryList from "@/components/CategoryList.vue";
import BlockRenderer from "@/components/BlockRenderer.vue";

import { PricingOption } from "@/store/types";

import { getUrl } from "@/helpers";

interface Data {
  selected: string;
  mdiStar: string;
}

export default Vue.extend({
  name: "Pricing",
  components: {
    BlockRenderer,
    CategoryList,
  },
  beforeMount() {
    this.$store.dispatch("directorio/GetPricing");
  },
  data(): Data {
    return {
      selected: "",
      mdiStar,
    };
  },
  computed: {
    options() {
      return this.$store.getters["directorio/pricingOptions"];
    },
    selectedOption() {
      if (this.options.length < 1) {
        return {};
      }
      return this.options.filter(
        (o: PricingOption) => o.id === this.selected
      )[0];
    },
  },
  methods: {
    getUrl,
  },
  watch: {
    selected() {
      if (!this.selected) return;
      this.$refs.br.SetBlocks(
        this.options.filter((o) => o.id === this.selected)[0].blocks.blocks
      );
    },
  },
});
